import * as React from 'react'

// Components
import Layout from '../components/layout/Layout'

const AboutPage = () => {
  return (
    <Layout pageTitle="About Me" headerClassPosition="sticky-top">
      <p>Hi there! I'm the proud creator of this site, which I built with Gatsby.</p>
    </Layout>
  )
}

export default AboutPage
